@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0.25;
  }
  50% {
    opacity: 0.50;
  }
  75% {
    opacity: 0.75;
  }
  100% {
    opacity: 1;
  }
}

.sticky-header {
  position: fixed !important;
  z-index: 9999;
  background: #fff;
  padding: 0.1rem;
  margin-top: -0.1rem !important;
  transition: margin-top 1s ease;
  animation: fadeIn 1s ease-in;
}

.area-served  .areaservedmultiselect {
    overflow-y: unset !important;
    max-height: unset !important;

}
 .areaservedmultiselect .searchWrapper {
    border: 0 !important;
}
.blank-partners-list {
    text-align: center;
    max-width: 550px;
    margin: 40px auto 150px;
    font-size: 25px;
}
.city-multiselect-input {
    position: relative;
  }
  .clear-all {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 11px;
    display: flex;
    align-items: center;
    background-color: #ffffff;
    border-radius: 12px;
    color: #161616;
    font-weight: normal;
    padding: 14px 13px;
    border: 1px solid #88F231;
  }
  .clear-all button {
    background: #88F231;
    border: 0;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    color: #fff;
    margin-left: 10px;
    font-size: 9px;
    transition: 0.3s;
  }
  .clear-all button:hover {
    background: #000000;
  }
  #multiselectContainerReact {
    padding: 0 !important;
  }

  #multiselectContainerReact .searchWrapper {
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 2px;
    position: relative;
  }
  #multiselectContainerReact #search_input {
    width: 100%;
  }
  .areaservedmultiselect {
  /* Styles for the collapsed state */
  width: 200px;
  transition: width 0.3s ease;
}

.areaservedmultiselect.expanded {
  /* Styles for the expanded state */
  width: 400px; /* Change the width to the desired value */
}
.user-name-with-salutation select{
  max-width: 100px;
}
.user-name-with-salutation input.form-control{
  width: calc(100% - 100px);
}
.info {
  font-size: 20px;
  padding-left: 5px;
  width: 20px;
  border-radius: 15px 50px 30px 5px;
border-color: blue;
}

/* Toolkit color class */
.mark-location-map i.fa.fa-circle-info ,.icon-info-sign i.fa.fa-circle-info{
  color: #000000;
}

/* Style for the input field and eye icon container */
.password-input-wrapper {
  width:100%;
  position: relative;
  display: inline-block;
}

/* Style for the eye icon button */
.eye-icon-button {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  outline: none;
}

/* Position the eye icon inside the input field */
.eye-icon-button i {
  font-size: 16px;
  color: #999; 
}

.infocus{
   outline: none !important;
}

.text-green{
  color: #163001 !important;
font-size:1rem;
font-weight: bold;
}


.input-value{
  padding: 3px;
}

.border{
  border: 0.5px solid rgb(211, 211, 211);
  border-radius: 5px;
}

.quote{
  text-decoration: underline;
}

.rejected{
  color: red
}

.text-dec{

  text-decoration: underline;
}

/* .pp-logo{
  width: ;
} */


.show-hide-btn {
  right: 20px;
  top: 38px;
  font-size: 14px;
}
.uploaded-quote {
  margin: -10px 0 0;
  position: relative;
  height: 70px;
}
.uploaded-quote a{
  position: absolute;
  left: 0;
  top: 0;
}


/* .custom-file-upload {
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f2f2f2;
  color: #333;
}

.custom-file-upload input[type="file"] {
  display: none;
} */
.solfin-dashboard .choose-file-input[type="file"] {
  padding-top: 14px;
  padding-bottom: 14px;
}

.btn.spinner-btn1 {
  width: 89.13px;
  height: 32.38px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn.spinner-btn2 {
  width: 77.61px;
  height: 32.38px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn.spinner-login-btn {
  width: 77.61px;
  height: 28.75px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#my-tooltip1 {
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 8px;
  border-radius: 5px;
  font-size: 10px;
  /* Additional styles as needed */
}
body .solfin-dashboard .leadform-btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
body .solfin-dashboard .clipboard-btn{
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: 1px solid rgba(255, 255, 255, 0.7);
}

.custom-datepicker{
  width : 100px;
  text-align: center;
}
.multiple-user-modal {
  font-size: 18px;
}
.cursor-pointer {
  cursor: pointer !important;
}